import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>© 2024 VAA - HOUSE OF GROUP 07</p>
    </footer>
  );
}
export default Footer;
